import "./loginFooter.scss";

const LoginFooter = () => {
  return (
    <div className="footer-login">
      <p className="copyright">Copyright© 2023 NEXTOR. All rights reserved.</p>
    </div>
  );
};

export default LoginFooter;
