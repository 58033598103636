import { AUTH_COOKIE } from "constants/index";
import { Home, Login } from "pages";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Routes, Route, useNavigate } from "react-router-dom";
import { getCookie } from "shared";
import PrivateRoute from "shared/PrivateRoute";
import { windowBottomSetting, windowTopSetting } from "store";

const App = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = getCookie(AUTH_COOKIE);

  const handleModeButton = () => {
    if (window.scrollY === 0) {
      dispatch(windowTopSetting(true));
    } else if (window.scrollY === document.documentElement.scrollHeight - document.documentElement.clientHeight) {
      dispatch(windowTopSetting(false));
      dispatch(windowBottomSetting(true));
      return;
    } else {
      dispatch(windowTopSetting(false));
      dispatch(windowBottomSetting(false));
      return;
    }
  };

  // mode button 스크롤시 위치
  useEffect(() => {
    window.addEventListener("scroll", handleModeButton);
    return () => {
      window.removeEventListener("scroll", handleModeButton); //clean up
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (token) {
      navigate(`/`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<PrivateRoute component={<Home />} authenticated={token} />} />
        <Route path="/login" element={<Login />} />
      </Routes>
    </div>
  );
};
export default App;
