import NormalButton from "components/NormalButton/NormalButton";
import "./header.scss";
import { useEffect, useState } from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { RootState, WindowState } from "store";
import useLinkList from "hook/useLinkList";

export interface HeaderTypes {
  tabRef: any;
  currentTab: any;
  setCurrentTab: any;
  selected?: boolean;
}

const Header = ({ tabRef, currentTab, setCurrentTab }: HeaderTypes) => {
  const { linkList: irLinkList } = useLinkList("IR");
  const windowSize: WindowState = useSelector((state: RootState) => state.window);
  const [irList, setIrList] = useState<any[]>([]);

  useEffect(() => {
    if (!irLinkList) return;
    setIrList(irLinkList?.links);
  }, [irLinkList]);

  return (
    <div className={classNames("header", { top: windowSize.top })}>
      <div
        className="logo"
        onClick={() => {
          window.scrollTo(0, 0);
        }}
      >
        <p>ZEMIVERSE</p>
      </div>
      <ul className="gnb">
        <li
          className={classNames({ on: currentTab === 0 && "on" })}
          onClick={() => {
            tabRef.current[0].scrollIntoView({ behavior: "smooth" });
            setCurrentTab(tabRef.current[0]);
          }}
        >
          소개 영상
        </li>
        <li
          className={classNames({ on: currentTab === 1 && "on" })}
          onClick={() => {
            tabRef.current[1].scrollIntoView({ behavior: "smooth" });
            setCurrentTab(tabRef.current[1]);
          }}
        >
          World
        </li>
        <li
          className={classNames({ on: currentTab === 2 && "on" })}
          onClick={() => {
            tabRef.current[2].scrollIntoView({ behavior: "smooth" });
            setCurrentTab(tabRef.current[2]);
          }}
        >
          Demo 체험
        </li>
      </ul>

      <div className="box-popup">
        <NormalButton
          title={`재미버스 소개서`}
          icon="trailing"
          iconType="download"
          iconColor="#FFF"
          iconSize={20}
          buttonStyle="round"
          hierarchy="primary"
          size="md"
          onClick={() => {}}
        />
        {irList?.length >= 1 && (
          <div className="box-list">
            {irList.map((data: any, idx: number) => (
              <NormalButton
                title={data.title}
                icon="trailing"
                iconType="download"
                iconColor="#667085"
                iconSize={20}
                buttonStyle="round"
                hierarchy="tertiary_gray"
                size="xs"
                onClick={() => {
                  window.open(data.url, `_blank`);
                }}
                key={idx}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
