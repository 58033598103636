import { isAxiosError } from "axios";
import { axios, ResponseDataType } from "apis/http";
import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "react-query/querykey";

export type Category = "VIDEO" | "APK" | "IR";

export type LinkList = {
  links: any;
  count: number;
};

// get link list
function getLinkList(queryString: string) {
  return axios.get<{ data: LinkList }>(`/demo/link/list/${queryString}`);
}

const getQueryString = (category: Category) => {
  let queryString = category;
  return queryString;
};

const useLinkList = (category: Category) => {
  const queryKey = [queryKeys.linkList, category];
  const queryString = getQueryString(category);

  const { data: linkList, error } = useQuery({
    queryKey,
    queryFn: () => getLinkList(queryString),
    select: (data) => data.data.data,
  });

  if (isAxiosError<ResponseDataType>(error)) {
    console.error("링크 리스트를 가져오는데 에러가 발생하였습니다.");
  }

  return { linkList, error };
};

export default useLinkList;
