import "./homeFooter.scss";

const HomeFooter = () => {
  return (
    <div className="footer">
      <div className="footer__inner">
        <div className="logo"></div>
        <div className="right">
          <div className="top">
            <p>
              사업자명 <span>넥스터 주식회사</span>
            </p>
            <p>
              사업자등록번호 <span>863-88-01484</span>
            </p>
            <p>
              대표 <span>전주천</span>
            </p>
            <p>
              주소 <span>서울특별시 강남구 논현로 419, 7층&#40;PMK 빌딩&#41;</span>
            </p>
            <p>
              사업 문의 <span>02.854.5807</span>
            </p>
            <p>
              IR 문의 <span>02.854.5807</span>
            </p>
            <p>
              이메일 <span>support@nextor.ai</span>
            </p>
          </div>
          <p className="copyright">Copyright© 2023 NEXTOR All rights reserved.</p>
        </div>
      </div>
    </div>
  );
};

export default HomeFooter;
