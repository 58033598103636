import ReactDOM from "react-dom/client";
// pages
import App from "./App";
// router
import { BrowserRouter } from "react-router-dom";
// seo
import { HelmetProvider } from "react-helmet-async";
// redux
import rootReducer from "./store/index";
import { legacy_createStore as createStore } from "redux";
import { Provider } from "react-redux";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
// css
import "./assets/css/reset.css";

const store = createStore(rootReducer);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // throwOnError: true,
      retry: 0,
      // suspense: true,
    },
  },
});

const element = (
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <HelmetProvider>
          <App />
        </HelmetProvider>
      </BrowserRouter>
      <ReactQueryDevtools />
    </QueryClientProvider>
  </Provider>
);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(element);
