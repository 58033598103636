import { Navigate } from "react-router-dom";

export interface PrivateRouteTypes {
  authenticated: string | null;
  component: any;
}

const PrivateRoute = ({ authenticated, component: Component }: PrivateRouteTypes) => {
  return authenticated ? Component : <Navigate to="/login" />;
};

export default PrivateRoute;
