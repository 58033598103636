import { combineReducers } from "redux";

import { persistReducer } from "redux-persist";
// import storage from 'redux-persist/lib/storage'; // 로컬 스토리지
import storageSession from "redux-persist/lib/storage/session"; // 세션 스토리지

import window from "./window";

const persistConfig = {
  key: "root",
  storage: storageSession,
};

const rootReducer = combineReducers({
  window,
});

export type RootState = ReturnType<typeof rootReducer>;
export default persistReducer(persistConfig, rootReducer);

export * from "./window";
