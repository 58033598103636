import { useState } from "react";
import { LoginFooter, NormalButton, InputBox } from "components";
import useSignIn from "hook/useSignIn";
import Icon from "../../shared/Icon";

import "./login.scss";

const ERROR_MESSAGE: { [key: string]: string } = {
  INVALID_PASSWORD: "패스워드가 일치하지 않습니다.",
  EMPTY_VALUE: "비밀번호를 입력하세요.",
  DEFAULT: "에러가 발생하였습니다.",
  TOKEN_EXPIRATION: "만료된 토큰입니다.", // TODO: redirect 하기
};

const Login = () => {
  const [inputValue, setInputValue] = useState("");
  const [error, setError] = useState("");
  const { mutate: signIn } = useSignIn();

  const handleOnLogin = async () => {
    const isEmptyInput = inputValue === "";

    if (isEmptyInput) {
      setError(ERROR_MESSAGE.EMPTY_VALUE);
      return;
    }

    signIn({ password: inputValue });
  };

  const handlePressEnter = (event: React.KeyboardEvent<object>) => {
    if (event.code !== "Enter") return;
    handleOnLogin();
  };

  return (
    <>
      <div className="container login">
        <div className="box-center">
          <div className="logo">
            <p>ZEMIVERSE</p>
          </div>
          <p className="description">재미버스 Demo 체험은 비공개로 접근 가능합니다.</p>
          <div className="box-input">
            <InputBox
              value={inputValue}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setInputValue(e.target.value);
              }}
              placeholder="Password"
              logMessage={error}
              logType={"error"}
              inputType="password"
              maxLength={20}
              onKeyPress={handlePressEnter}
            />
            <NormalButton title={`LOGIN`} buttonStyle="box" size="md" hierarchy="primary" onClick={handleOnLogin} />
          </div>

          <div className="box-bottom">
            <p>메타버스 사업문의</p>
            <div className="box-contact">
              <div>
                <Icon className="phone-fill" icon="phone-fill" size={20} color={"#344054"} />
                <p>02-854-5807</p>
              </div>
              <div>
                <Icon className="mail" icon="mail" size={20} color={"#344054"} />
                <a href="mailto:support@nextor.ai">support@nextor.ai</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <LoginFooter />
    </>
  );
};

export default Login;
