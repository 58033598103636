import { useEffect, useRef, useState } from "react";
import { Header, HomeFooter, LoginFooter, NormalButton } from "components/index";
import "./home.scss";
import classNames from "classnames";
import ReactPlayer from "react-player";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import Icon from "../../shared/Icon";
import useLinkList from "hook/useLinkList";
import "swiper/css";
import "swiper/scss/autoplay";

const Home = () => {
  const { linkList: videoLinkList } = useLinkList("VIDEO");
  const { linkList: apkLinkList } = useLinkList("APK");
  const { linkList: irLinkList } = useLinkList("IR");

  // 해당위치로 스크롤하기
  const tabRef = useRef<any>([]);
  const [currentTab, setCurrentTab] = useState(0);

  const [nowVideo, setNowVideo] = useState(0);
  const [videoList, setVideoList] = useState<any[]>([]);
  const [apkList, setApkList] = useState<any[]>([]);
  const [irList, setIrList] = useState<any[]>([]);

  const useLinkListEffect = (
    linkList: { links: any } | null | undefined,
    setList: React.Dispatch<React.SetStateAction<any[]>>
  ) => {
    useEffect(() => {
      console.log(`test >>>>>`, linkList);
      if (linkList) {
        setList(linkList.links);
      }
    }, [linkList, setList]);
  };

  useLinkListEffect(videoLinkList, setVideoList);
  useLinkListEffect(apkLinkList, setApkList);
  useLinkListEffect(irLinkList, setIrList);

  const [numPages, setNumPages] = useState<number | null>(null);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages);
  }

  return (
    <>
      <Header tabRef={tabRef} currentTab={currentTab} setCurrentTab={setCurrentTab} />
      <div className="layout-home">
        <div className="container">
          <div className="content1">
            <div className={classNames("box__scroll__down top")}>
              <p>SCROLL</p>
              <div className="box">
                <div className="bar"></div>
              </div>
            </div>
            <div className="content1__inner">
              <div className="logo"></div>
              <div className="box-popup">
                <NormalButton
                  title={`재미버스 소개서`}
                  icon="trailing"
                  iconType="download"
                  iconColor="#FFF"
                  iconSize={24}
                  buttonStyle="round"
                  hierarchy="primary"
                  size="txl"
                  onClick={() => {}}
                />
                {irList?.length >= 1 && (
                  <div className="box-list">
                    {irList.map((data: any, idx: number) => (
                      <NormalButton
                        title={data.title}
                        icon="trailing"
                        iconType="download"
                        iconColor="#667085"
                        iconSize={20}
                        buttonStyle="round"
                        hierarchy="tertiary_gray"
                        size="md"
                        onClick={() => {
                          window.open(data.url, `_blank`);
                          // const file = new Blob([data.url], { type: "application/pdf" });
                          // const fileURL = URL.createObjectURL(file);
                          // window.open(`https://docs.google.com/gview?url=${data.url}&embedded=true`, "_blank");
                          // window.location.href = fileURL;
                          // window.open("data:application/pdf;base64, " + data.url);
                        }}
                        key={idx}
                      />
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="content2" ref={(el) => (tabRef.current[0] = el)}>
            <div className="content2__inner">
              <p className="titie">재미버스 소개 영상</p>
              <div className="box-video">
                <ReactPlayer
                  url={videoList[nowVideo]?.url}
                  className="player"
                  style={{ objectFit: "cover" }}
                  playing
                  muted
                  loop
                  controls
                  playsinline
                />
              </div>
              {videoList?.length >= 1 && (
                <div className="box-list">
                  {videoList.map((data: any, idx: number) => (
                    <NormalButton
                      title={data.title}
                      buttonStyle="box"
                      hierarchy={nowVideo === idx ? "primary" : "quaternary"}
                      size="md"
                      onClick={() => {
                        setNowVideo(idx);
                      }}
                      key={idx}
                    />
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className="content3" ref={(el) => (tabRef.current[1] = el)}>
            <p className="description">Real World</p>
            <p className="title">
              핫플레이스, 관광지에서
              <br />
              즐기는 이색체험
            </p>

            <div className="box-swiper">
              <div className="swiper-button-next">
                <Icon className="chevron-right" icon="chevron-right" size={24} color={"#FFF"} />
              </div>
              <div className="swiper-button-prev">
                <Icon className="chevron-left" icon="chevron-left" size={24} color={"#FFF"} />
              </div>
              <div className="custom-pagination"></div>
              <Swiper
                modules={[Navigation, Pagination]}
                slidesPerView={1}
                initialSlide={0}
                centeredSlides
                pagination={{
                  type: "fraction",
                  el: ".custom-pagination",
                }}
                navigation={{
                  nextEl: ".swiper-button-next",
                  prevEl: ".swiper-button-prev",
                }}
                rewind
                loop
                // ref={navigationRef}
              >
                <SwiperSlide>
                  <img src={`https://picsum.photos/1170/1000`} alt="다운로드 스와이퍼 이미지 7" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={`https://picsum.photos/1170/820`} alt="다운로드 스와이퍼 이미지 3" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={`https://picsum.photos/1170/830`} alt="다운로드 스와이퍼 이미지 4" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={`https://picsum.photos/1170/840`} alt="다운로드 스와이퍼 이미지 5" />
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
          <div className="content4" ref={(el) => (tabRef.current[2] = el)}>
            <div className="content4__inner">
              <p className="title">ZEMIVERSE Demo 체험해보세요.</p>
              <p className="description">현재 데모버전의 경우 안드로이드 버전만 체험 가능합니다.</p>
              <div className="box-buttons">
                <div className="box-popup">
                  <NormalButton
                    title={`Demo APK 다운로드`}
                    icon="trailing"
                    iconType="download"
                    iconColor="#FFF"
                    iconSize={24}
                    buttonStyle="box"
                    hierarchy="primary"
                    size="txl"
                    onClick={() => {}}
                  />
                  {apkList?.length >= 1 && (
                    <div className="box-list">
                      <p className="description">Demo 버전을 다운로드 후 설치해 주세요.</p>
                      {apkList.map((data: any, idx: number) => (
                        <NormalButton
                          title={data.title}
                          icon="trailing"
                          iconType="download"
                          iconColor="#667085"
                          iconSize={20}
                          buttonStyle="round"
                          hierarchy="tertiary_gray"
                          size="md"
                          onClick={() => {
                            window.open(data.url, `_blank`);
                          }}
                          key={idx}
                        />
                      ))}
                    </div>
                  )}
                </div>
                <NormalButton
                  title={`메뉴얼 다운로드`}
                  icon="trailing"
                  iconType="download"
                  iconColor="#7F56D9"
                  iconSize={24}
                  buttonStyle="box"
                  hierarchy="primary_line"
                  size="txl"
                  onClick={() => {
                    alert(`현재 메뉴얼이 없습니다.`);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <HomeFooter />
      </div>
    </>
  );
};

export default Home;
